import axios from 'axios';
import jwt_decode from 'jwt-decode';
import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

export const getTempToken = async () => {
	try {
		const resp = await axios.get(
			`${process.env.VUE_APP_API_URL}User/token?id=1`,
			{
				headers: {
					'x-application-context': 'CMS'
				}
			}
		);
		JwtService.saveToken(resp.data.token);
		return resp.data.token;
	} catch (error) {
		console.log('temptoken error:', error);
		return;
	}
};

// export const getUserToken = callback => {
// 	const data = JSON.parse(getData('loginData'));
// 	const date = new Date(data.date);
// 	const now = new Date();
// 	let diff = (now.getTime() - date.getTime()) / 1000;
// 	diff /= 90 * (24 * 60); // compare token for 90 days

// 	if (Math.abs(Math.round(diff)) < 60) {
// 		callback(data.token);
// 	} else {
// 		clearData(); // ask user to login again
// 	}
// };

export interface User {
	userName: string;
	role: string;
	subscription: string;
	surname: string;
	email: string;
	password: string;
	api_token: string;
}

interface Customer {
	selectedCustomer: string;
}
export interface UserAuthInfo {
	errors: unknown;
	user: User;
	customers: Customer;
	isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
	errors = null;
	user = {} as User;
	customers = {} as Customer;
	isAuthenticated = !!JwtService.getToken();

	/**
	 * Get current user object
	 * @returns User
	 */
	get currentUser(): User {
		return this.user;
	}

	/**
	 * Verify user authentication
	 * @returns boolean
	 */
	get isUserAuthenticated(): boolean {
		return this.isAuthenticated;
	}

	/**
	 * Get authentification errors
	 * @returns array
	 */
	get getErrors() {
		return this.errors;
	}

	/**
	 * Get delected customer array
	 * @returns object
	 */
	get selectedCustomer(): string {
		return this.customers.selectedCustomer;
	}

	@Mutation
	[Mutations.SET_ERROR](error) {
		this.errors = error;
	}

	@Mutation
	[Mutations.SET_AUTH](user) {
		this.isAuthenticated = true;
		this.user = user;
		this.errors = null;
		JwtService.saveToken(user.token);
	}

	@Mutation
	[Mutations.SET_USER](user) {
		this.user = user;
	}

	@Mutation
	[Mutations.SET_TOKEN](token) {
		JwtService.saveToken(token);
	}

	@Mutation
	[Mutations.SET_PASSWORD](password) {
		this.user.password = password;
	}

	@Mutation
	[Mutations.PURGE_AUTH]() {
		this.isAuthenticated = false;
		this.user = {} as User;
		this.errors = null;
		this.customers = {} as Customer;
		JwtService.destroyToken();
		localStorage.clear();
	}

	@Mutation
	[Mutations.SET_Selected_CUSTOMER_MUTATION](payload) {
		this.customers.selectedCustomer = payload;
	}

	@Action
	async [Actions.LOGIN](credentials) {
		await getTempToken();
		if (JwtService.getToken()) {
			ApiService.setHeader();
			return ApiService.post('User/authenticate', credentials)
				.then(({ data }) => {
					console.log('data:', { data });
					const loginData = JSON.stringify({
						date: new Date(),
						role: data.role,
						token: data.token,
						userName: data.userName,
						subscription: data.subscription
					});
					localStorage.setItem('loginData', loginData);
					this.context.commit(Mutations.SET_AUTH, data);
					if (data.role === 'Customer') {
						const decode: any = jwt_decode(data.token);
						localStorage.setItem('selectedCustomer', decode.customerId);
						this.context.commit(
							Mutations.SET_Selected_CUSTOMER_MUTATION,
							decode.userId
						);
					}
				})
				.catch(({ response }) => {
					this.context.commit(
						Mutations.SET_ERROR,
						response.data.description || 'unAuthenticated'
					);
				});
		}
	}

	@Action
	[Actions.LOGOUT]() {
		this.context.commit(Mutations.PURGE_AUTH);
	}

	@Action
	[Actions.REGISTER](credentials) {
		return ApiService.post('register', credentials)
			.then(({ data }) => {
				this.context.commit(Mutations.SET_AUTH, data);
			})
			.catch(({ response }) => {
				this.context.commit(Mutations.SET_ERROR, response.data.description);
			});
	}

	@Action
	async [Actions.FORGOT_PASSWORD](payload) {
		await getTempToken();
		ApiService.setHeader();
		return ApiService.post('User/forgotpassword', payload)
			.then(() => {
				this.context.commit(Mutations.PURGE_AUTH);
			})
			.catch(({ response }) => {
				this.context.commit(Mutations.PURGE_AUTH);
				this.context.commit(Mutations.SET_ERROR, response.data.description);
			});
	}

	@Action
	[Actions.VERIFY_AUTH](payload) {
		if (JwtService.getToken()) {
			ApiService.setHeader();
			const loginData = JSON.parse(localStorage.getItem('loginData') || '{}');
			this.context.commit(Mutations.SET_AUTH, loginData);
			const selectedCustomer = localStorage.getItem('selectedCustomer') || '';
			this.context.commit(
				Mutations.SET_Selected_CUSTOMER_MUTATION,
				selectedCustomer
			);
			// ApiService.setHeader();
			// ApiService.post('verify_token', payload)
			// 	.then(({ data }) => {
			// 		this.context.commit(Mutations.SET_AUTH, data);
			// 	})
			// 	.catch(({ response }) => {
			// 		// this.context.commit(Mutations.SET_ERROR, response.data.description);
			// 		// this.context.commit(Mutations.PURGE_AUTH);
			// 	});
		} else {
			this.context.commit(Mutations.PURGE_AUTH);
		}
	}

	@Action
	[Actions.SWITCH_CUSTOMER](payload) {
		if (JwtService.getToken()) {
			ApiService.setHeader();
			return ApiService.post(`User/customerswitch?customerId=${payload}`, {})
				.then(({ data }) => {
					console.log({ data });
					const loginData = JSON.stringify({
						date: new Date(),
						role: data.role,
						token: data.token,
						userName: data.userName,
						subscription: data.subscription
					});
					localStorage.setItem('loginData', loginData);
					this.context.commit(Mutations.SET_AUTH, data);
					if (data.role === 'Admin') {
						const decode: any = jwt_decode(data.token);
						localStorage.setItem('selectedCustomer', decode.customerId);
						this.context.commit(
							Mutations.SET_Selected_CUSTOMER_MUTATION,
							decode.customerId
						);
					}
				})
				.catch(({ response }) => {
					this.context.commit(
						Mutations.SET_ERROR,
						response.data.description || 'unAuthenticated'
					);
				});
		}
	}

	@Action
	[Actions.SET_Selected_CUSTOMER_ACTION](payload) {
		this.context.commit(Mutations.SET_Selected_CUSTOMER_MUTATION, payload);
	}
}
