import LayoutConfigTypes from '@/core/config/LayoutConfigTypes';

const config: LayoutConfigTypes = {
	themeName: 'Metronic',
	themeVersion: '8.0.37',
	demo: 'demo1',
	main: {
		type: 'default',
		primaryColor: '#009EF7',
		logo: {
			dark: 'media/logos/streampac-white.svg',
			light: 'media/logos/streampac-black.svg'
		}
	},
	illustrations: {
		set: 'sketchy-1'
	},
	loader: {
		logo: 'media/logos/streampac-white.svg',
		display: true,
		type: 'default'
	},
	scrollTop: {
		display: true
	},
	header: {
		display: true,
		menuIcon: 'font',
		width: 'fluid',
		fixed: {
			desktop: true,
			tabletAndMobile: true
		}
	},
	toolbar: {
		display: true,
		width: 'fixed',
		fixed: {
			desktop: false,
			tabletAndMobile: false
		}
	},
	aside: {
		display: true,
		theme: 'dark',
		fixed: true,
		menuIcon: 'svg',
		minimized: false,
		minimize: true,
		hoverable: true
	},
	content: {
		width: 'fixed'
	},
	footer: {
		width: 'fluid'
	}
};

export default config;
