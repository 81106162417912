import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const authRoutes = [
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const routes = [
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/CleanDashboard.vue"),
        meta: { authorize: ["Admin", "Customer"] },
      },
      {
        path: "/customers",
        name: "customers",
        component: () => import("@/views/apps/customers/Customers.vue"),
        meta: { authorize: ["Admin"] },
        children: [
          {
            path: "/customers",
            name: "customers-listing",
            component: () =>
              import("@/views/apps/customers/CustomersListing.vue"),
            meta: { authorize: ["Admin"] },
          },
          {
            path: "/customer/edit/:id",
            name: "apps-customers-edit",
            component: () =>
              import("@/views/apps/customers/CustomerAddEdit.vue"),
            meta: { authorize: ["Admin"] },
          },
          {
            path: "/customer/importcustomer",
            name: "apps-customer-import",
            component: () => import("@/components/files/FileImport.vue"),
            meta: { authorize: ["Admin"] },
            props: {
              importedEntityName: "Customer",
              apiLink: "Customer/importCustomers",
              breadcrumbs: ["Customers"],
            },
          },
          {
            path: "/customer/add",
            name: "apps-customers-add",
            component: () =>
              import("@/views/apps/customers/CustomerAddEdit.vue"),
            meta: { authorize: ["Admin"] },
          },
        ],
      },
      {
        path: "/enumerations",
        name: "apps-enumerations-listing",
        component: () =>
          import("@/views/apps/enumerations/EnumerationListing.vue"),
        meta: { authorize: ["Admin", "Customer"] },
      },
      {
        path: "/users",
        name: "apps-users",
        component: () => import("@/views/apps/users/Users.vue"),
        meta: { authorize: ["Admin", "Customer"] },
        children: [
          {
            path: "/users",
            name: "apps-users-listing",
            component: () => import("@/views/apps/users/UsersListing.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/user/edit/:id",
            name: "apps-user-edit",
            component: () => import("@/views/apps/users/UserAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/user/add",
            name: "apps-user-add",
            component: () => import("@/views/apps/users/UserAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/user/importuser",
            name: "apps-user-import",
            component: () => import("@/views/apps/users/NewUserImport.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/user/subscriptions/:id",
            name: "apps-user-subscriptions",
            component: () => import("@/views/apps/users/UserSubscriptions.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
        ],
      },
      {
        path: "/userlevel",
        name: "apps-user-level",
        component: () => import("@/views/apps/userLevel/UserLevel.vue"),
        meta: { authorize: ["Admin"] },
        children: [
          {
            path: "/userlevel",
            name: "apps-user-level-listing",
            component: () =>
              import("@/views/apps/userLevel/UserLevelListing.vue"),
            meta: { authorize: ["Admin"] },
          },
          {
            path: "/userlevel/edit/:id",
            name: "apps-user-level-edit",
            component: () =>
              import("@/views/apps/userLevel/UserLevelAddEdit.vue"),
            meta: { authorize: ["Admin"] },
          },
          {
            path: "/userlevel/add",
            name: "apps-user-level-add",
            component: () =>
              import("@/views/apps/userLevel/UserLevelAddEdit.vue"),
            meta: { authorize: ["Admin"] },
          },
        ],
      },
      {
        path: "/articles",
        name: "apps-articles",
        component: () => import("@/views/apps/articles/Articles.vue"),
        meta: { authorize: ["Admin", "Customer"] },
        children: [
          {
            path: "/articles",
            name: "apps-articles-listing",
            component: () =>
              import("@/views/apps/articles/ArticlesListing.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/article/edit/:id",
            name: "apps-articles-edit",
            component: () => import("@/views/apps/articles/ArticleAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/article/add",
            name: "apps-articles-add",
            component: () => import("@/views/apps/articles/ArticleAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
        ],
      },
      {
        path: "/cmsmedia",
        name: "apps-cmsmedia",
        component: () => import("@/views/apps/cmsMedia/CMSMedia.vue"),
        meta: { authorize: ["Admin", "Customer"] },
        children: [
          {
            path: "/cmsmedia",
            name: "apps-cmsmedia-listing",
            component: () =>
              import("@/views/apps/cmsMedia/CMSMediaListing.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/cmsmedia/details/:id",
            name: "apps-cmsmedia-details",
            component: () =>
              import("@/views/apps/cmsMedia/CMSMediaDetails.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/cmsmedia/add",
            name: "apps-cmsmedia-Add",
            component: () =>
              import("@/views/apps/cmsMedia/CMSMediaAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
        ],
      },
      {
        path: "/videocontent",
        name: "apps-videocontent",
        component: () => import("@/views/apps/videoContent/VideoContent.vue"),
        meta: { authorize: ["Admin", "Customer"] },
        children: [
          {
            path: "/videocontent",
            name: "apps-videocontent-listing",
            component: () =>
              import("@/views/apps/videoContent/VideoContentListing.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/videocontent/add",
            name: "apps-videocontent-add",
            component: () =>
              import("@/views/apps/videoContent/VideoContentAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/videocontent/edit/:id",
            name: "apps-videocontent-edit",
            component: () =>
              import("@/views/apps/videoContent/VideoContentAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
        ],
      },
      {
        path: "/playlist",
        name: "apps-playlist",
        component: () => import("@/views/apps/playlist/Playlist.vue"),
        meta: { authorize: ["Admin", "Customer"] },
        children: [
          {
            path: "/playlist",
            name: "apps-playlist-listing",
            component: () =>
              import("@/views/apps/playlist/PlaylistListing.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/playlist/add",
            name: "apps-playlist-add",
            component: () =>
              import("@/views/apps/playlist/PlaylistAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/playlist/edit/:id",
            name: "apps-playlist-edit",
            component: () =>
              import("@/views/apps/playlist/PlaylistAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
        ],
      },
      {
        path: "/playlistrule",
        name: "apps-playlistrule",
        component: () => import("@/views/apps/playlistRule/PlaylistRule.vue"),
        meta: { authorize: ["Admin", "Customer"] },
        children: [
          {
            path: "/playlistrule",
            name: "apps-playlistrule-listing",
            component: () =>
              import("@/views/apps/playlistRule/PlaylistRuleListing.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/playlistrule/add",
            name: "apps-playlistrule-add",
            component: () =>
              import("@/views/apps/playlistRule/PlaylistRuleAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/playlistrule/edit/:id",
            name: "apps-playlistrule-edit",
            component: () =>
              import("@/views/apps/playlistRule/PlaylistRuleAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
        ],
      },
      {
        path: "/template",
        name: "apps-template",
        component: () => import("@/views/apps/template/Template.vue"),
        meta: { authorize: ["Admin", "Customer"] },
        children: [
          {
            path: "/template/:type",
            name: "apps-template-listing",
            component: () =>
              import("@/views/apps/template/TemplateListing.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/template/add/template",
            name: "apps-Template-add",
            component: () =>
              import("@/views/apps/template/DynamicContentAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/template/edit/:id",
            name: "apps-Template-edit",
            component: () =>
              import("@/views/apps/template/DynamicContentAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          // {
          // 	path: '/template/edit/:id',
          // 	name: 'apps-Template-edit',
          // 	component: () =>
          // 		import('@/views/apps/template/TemplateAddEdit.vue'),
          // 	meta: { authorize: ['Admin', 'Customer'] }
          // }
        ],
      },
      {
        path: "/templatecontent",
        name: "apps-Template-content",
        component: () => import("@/views/apps/template/Template.vue"),
        meta: { authorize: ["Admin", "Customer"] },
        children: [
          {
            path: "/templatecontent/:templateId",
            name: "apps-Template-content-listing",
            component: () =>
              import("@/views/apps/TemplateContent/TemplateContentListing.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/templatecontent/add/:id",
            name: "apps-template-content-add",
            component: () =>
              import("@/views/apps/template/DynamicContentAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/templatecontent/edit/:id/:contentId",
            name: "apps-Template-content-edit",
            component: () =>
              import("@/views/apps/template/DynamicContentAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
        ],
      },
      {
        path: "/pagelayout",
        name: "apps-pageLayout",
        component: () => import("@/views/apps/pageLayout/PageLayout.vue"),
        meta: { authorize: ["Admin", "Customer"] },
        children: [
          {
            path: "/pagelayout",
            name: "apps-pageLayout-listing",
            component: () =>
              import("@/views/apps/pageLayout/PageLayoutListing.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/pagelayout/add",
            name: "apps-pageLayout-add",
            component: () =>
              import("@/views/apps/pageLayout/PageLayoutAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/pagelayout/edit/:id",
            name: "apps-pageLayout-edit",
            component: () =>
              import("@/views/apps/pageLayout/PageLayoutAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
        ],
      },
      {
        path: "/priceplan",
        name: "apps-priceplan",
        component: () => import("@/views/apps/pricePlan/PricePlan.vue"),
        meta: { authorize: ["Admin", "Customer"] },
        children: [
          {
            path: "/priceplan",
            name: "apps-priceplan-listing",
            component: () =>
              import("@/views/apps/pricePlan/PricePlanListing.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/priceplan/add",
            name: "apps-priceplan-add",
            component: () =>
              import("@/views/apps/pricePlan/PricePlanAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/priceplan/edit/:id",
            name: "apps-priceplan-edit",
            component: () =>
              import("@/views/apps/pricePlan/PricePlanAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
        ],
      },
      {
        path: "/vouchers",
        name: "apps-vouchers",
        component: () => import("@/views/apps/vouchers/Vouchers.vue"),
        meta: { authorize: ["Admin", "Customer"] },
        children: [
          {
            path: "/vouchers",
            name: "apps-vouchers-listing",
            component: () =>
              import("@/views/apps/vouchers/VouchersListing.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/vouchers/importvouchers",
            name: "apps-vouchers-import",
            component: () =>
              import("@/views/apps/vouchers/NewVoucherImport.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/voucher/add",
            name: "apps-voucher-add",
            component: () => import("@/views/apps/vouchers/VoucherAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/voucher/edit/:id",
            name: "apps-voucher-edit",
            component: () => import("@/views/apps/vouchers/VoucherAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
        ],
      },
      {
        path: "/overviews",
        name: "apps-overviews",
        component: () => import("@/views/apps/overviews/Overviews.vue"),
        meta: { authorize: ["Admin", "Customer"] },
        children: [
          {
            path: "/overviews",
            name: "apps-overviews-listing",
            component: () =>
              import("@/views/apps/overviews/OverviewsListing.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/overviews/add",
            name: "apps-overview-add",
            component: () =>
              import("@/views/apps/overviews/OverviewAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
          {
            path: "/overviews/edit/:id",
            name: "apps-overview-edit",
            component: () =>
              import("@/views/apps/overviews/OverviewAddEdit.vue"),
            meta: { authorize: ["Admin", "Customer"] },
          },
        ],
      },
      {
        path: "/adyen",
        name: "apps-adyen",
        component: () => import("@/views/apps/adyen/Adyen.vue"),
        meta: { authorize: ["Admin"] },
        children: [
          {
            path: "/adyen",
            name: "apps-adyen-page",
            component: () =>
              import("@/views/apps/adyen/AdyenCreatePayment.vue"),
            meta: { authorize: ["Admin"] },
          },
        ],
      },
    ],
  },
];

routes.push(...authRoutes);

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  // reset config to initial state
  await store.commit(Mutations.RESET_LAYOUT_CONFIG);

  await store.dispatch(Actions.VERIFY_AUTH, {
    api_token: JwtService.getToken(),
  });

  const { authorize } = to.meta;
  const role = store.getters.currentUser["role"];

  if (!role && to.path === "/") {
    console.log("no role");
    return next({ path: "/sign-in", replace: true });
  }

  if (role && to.path === "/") {
    console.log("role exist");
    return next({ path: "/dashboard", replace: true });
  }

  if (authorize) {
    if (!role) {
      console.log("authorize but no role found");
      return next({
        replace: true,
        path: "/sign-in",
      });
    }

    if (role && authorize.length && !authorize.includes(role)) {
      console.log("authorized but access restricted");
      return next({
        replace: true,
        path: "/dashboard",
      });
    }
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  next();
});

router.onError((error) => {
  console.log("router error:", error.message);

  if (
    error.message.includes("Loading chunk") &&
    error.message.includes("failed")
  ) {
    console.log("starting condition");
    window.location.reload();
  }
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    console.log("condition 1 is true");
    window.location.reload();
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    console.log("condition 2 is true");
    window.location.reload();
  } else if (/loading chunk \d* failed./i.test(error.message)) {
    console.log("condition 3 is true");
    window.location.reload();
  } else if (/Loading chunk \d* failed./i.test(error.message)) {
    console.log("condition 4 is true");
    window.location.reload();
  } else if (/Loading chunk chunk-.* failed./i.test(error.message)) {
    console.log("condition 5 is true");
    window.location.reload();
  }
});

export default router;
